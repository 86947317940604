<template>
<Header />
<div class="mbody">
	<div class="layout">
		<Detail :id="gameid" />
		
	</div>
</div>
<Footer />
</template>

<script>
	import Header from './HeaderEmpty.vue';
	import Footer from './Footer.vue';
	import Detail from '@/views/Detail.vue';
	import { getGameList } from "@/api/index";
	export default {
		components:{
			Header,Footer,Detail
		},
		data(){
			return{
				gameid:0,
				gameList:[],
				// 查询参数
				queryParams: {
				  orderByField: 'listorder',
				  current: 1,
				  size:10,
				  ostype: 1
				},
			}
		},
		created(){
			this.gameid = parseInt(this.$route.params.id);
			this.getHotList();
		},
		methods:{
			getHotList() {
				let query = Object.assign({isHot:1},this.queryParams);
				getGameList(query).then(response => {
					  this.gameList = response.data.records;
					}
				);
			}
		}
	}
</script>
<style lang="less" scoped>
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	.layout{
		display: flex;flex-wrap: nowrap;width: 1200px;justify-content: space-between;margin-bottom: 20px;
		
		.ranklist{
			width: 380px;background: #fff;box-shadow: 0 0 20px #eee;padding: 20px;box-sizing: border-box;
			.title{width: 100%;}
			ul{
				display: flex;align-items: center;flex-wrap: wrap;
				li{
					width: 100%;display: flex;padding-bottom: 20px;border-bottom: 1px solid #eee;margin-bottom: 20px;
					.rank{
						width: 24px;display: flex;align-items: center;
						span{display: inline-block;background: #999;color: #fff;padding: 0 3px;}
					}
					.desc{flex: 1;padding: 0 10px;display: flex;align-items: center;}
					.btn{
						width: 76px;
						a{
							display: inline-block;border: 1px solid #14B9C8;border-radius: 20px;color: #14B9C8;width: 100%;text-align: center;font-size: 12px;line-height: 20px;
							&:hover{background: #14B9C8;color: #fff;}
						}
					}
					&:first-child{
						.rank{
							span{background: #fa541c;}
						}
					}
					&:nth-child(2){
						.rank{
							span{background: #ff7a45;}
						}
					}
					&:nth-child(3){
						.rank{
							span{background: #ff9c6e;}
						}
					}
				}
			}
		}
		
	}
</style>
