import request from '@/utils/request'

// 获取游戏列表
export const getGameList = (data) => {
  return request({
    url: '/serv/game/getGameList',
    method: 'get',
	params: data
  })
}
export const getGameDetail = (id) => {
  return request({
    url: '/serv/game/getDetail',
    method: 'get',
	params: {gameId:id}
  })
}