<template>
		<div class="bg">
			<div class="topnav1">
				<div class="logoImg">
					<router-link to="/">
						<img src="@/assets/images/logo-xiaox.png" />
						<!-- 潇湘畅游 -->
					</router-link>
				</div>
				<a @click="drawer = true"><i class="el-icon-more"></i></a>
			</div>
			<el-drawer
			  title=""
			  v-model="drawer"
			  direction="btt"
			  append-to-body="true"
			  size="100%"
			  >
			  <div class="mtabList">
				  <ul>
					  <li><a href="/m"><span class="ls">首页</span><i class="el-icon-arrow-right"></i></a></li>
					  <li><a href="/m/connect">联系我们<i class="el-icon-arrow-right"></i></a></li>
				  </ul>
			  </div>
			</el-drawer>
		</div>
		
</template>

<script>
	export default{
		data(){
		  return{
		    drawer:false
		  }
		},
		watch: {
		  '$route':'getPath'
		},
		methods: {
		  getPath(){
		    let url = this.$route.path;
		    if(url.indexOf('about/') != -1){
		      this.tabStr = 'about';
		    }else if(url.indexOf('games/') != -1){
		      this.tabStr = 'games';
		    }else{
		      this.tabStr = '';
		    }
		    
		  }
		}
	}
</script>

<style lang="less" scoped>
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	.bg{
		width: 100%;background: #fff;
		.topnav1{
			  height: 48px !important;width: 100% !important;padding: 0 16px;box-sizing: border-box;display: flex;justify-content: space-between;align-items: center;
			  .logoImg{
				  height: 48px;width: 65px!important;
				  a{
					  display: block;height: 100%;
					  img{width: 100%;margin-top: 12px;}
				  }
			  }
		}
	}
	.ls{letter-spacing: 2em;}
  
</style>
