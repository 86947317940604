<template>
	<div id="footer">
	    <div class="footerBox">
			<p class="tags">
				<!-- <a href="/pc/about">关于我们</a> -->
				<a href="/pc/connect">联系我们</a>
				<a href="/clausePrivacy">隐私政策</a>
				<a href="/clauseUser">用户协议</a>
			</p>
			<p><a class="mr5" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">湘ICP备20007418号-1</a>Copyright© 2022 长沙潇湘畅游网络科技有限公司 版权所有</p>
			<!-- <p>抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活</p> -->
	    </div>
	</div>
</template>

<script>
</script>

<style lang="less" scoped>
  @import '@/assets/css/common';
</style>
