<template>
	<Header />
	<div class="mainbody">
		<el-alert style="width: 100%;" title="客服QQ: 3008508010" type="error" :closable="false" />
		<div class="service">
			<ul>
				<li>
					<div class="iconBox purple">
						<span>
							<i class="el-icon-s-ticket"></i>
						</span>
					</div>
					<div class="desBox">
						<p class="title">返利帮助</p>
						<p>
							<el-popover placement="bottom-start" title="如何申请返利" :width="600" trigger="hover" >
							    <template #reference>
							      <span>如何申请返利</span>
							    </template>
								<template #default>
								  <div>
									  <el-link type="primary" :underline="false">① 什么是游戏返利：</el-link>
									  <p>游戏返利是指当充值达到返利活动要求后，可以获得的游戏货币或道具的奖励。一般为变态版、满Vip版游戏标配活动；</p>
									  <el-link type="primary" :underline="false">② 如何申请返利：</el-link>
									  <p>方法一：奇葩游戏首页，底部右下角“联系客服”申请；</p>
									  <p>方法二：在奇葩游戏内搜索当前游戏，在游戏详情页里的底部右下角“联系客服”也可以申请；</p>
									  <el-link type="danger" :underline="false">注：道具申请可以在申请时，将道具内容填写提交给客服</el-link>
									  <p>（部分自选道具需要注明详细道具名称）</p>
								  </div>
								</template>
							</el-popover>
							<el-popover placement="bottom-start" title="无法申请返利" :width="500" trigger="hover" >
							    <template #reference>
							      <span>无法申请返利</span>
							    </template>
								<template #default>
								  <div>
									  <p>① 请确保当前申请返利账号和游戏充值账号一致；</p>
									  <p>② 请核对您单日充值总额是否满足返利活动要求；</p>
									  <p>③ 部分游戏返利仅限充值后48小时内申请，逾期无法申请，请联系客服；</p>
								  </div>
								</template>
							</el-popover>
							<el-popover placement="bottom-start" title="返利到账时间" :width="500" trigger="hover" >
							    <template #reference>
							      <span>返利到账时间</span>
							    </template>
								<template #default>
								  <div>
									  <p>返利一般会在申请后，24小时至48小时内完成发放；</p>
									  <p>部分游戏周末或节假日返利会稍有延迟，如有延迟一般会在工作日尽快安排发放，无需担心；</p>
									  <el-link type="danger" :underline="false">
										  注意：当日的返利申请，建议在确认总金额后再提交，避免申请后继续充值错过更高的返还奖励；
										  如若延期，确定返利依然未收到，可联系客服协助核实；
									  </el-link>
								  </div>
								</template>
							</el-popover>
							<el-popover placement="bottom-start" title="返利到账方式" :width="500" trigger="hover" >
							    <template #reference>
							      <span>返利到账方式</span>
							    </template>
								<template #default>
								  <div>
									  <p>返利到账形式一般有以下几种：</p>
									  <p>① 发放在游戏内邮件中，注意查收领取；</p>
									  <p>② 直接发放在背包中，需要使用后才可获得奖励（如元宝卡、礼包等）</p>
									  <p>③ 在游戏界面某个【领奖】图标中进行领取；</p>
									  <p>④ 激活码形式发放，联系客服获取，然后在游戏内激活；</p>
									  <p>⑤ 直接发放到角色，需要自己留意金额道具相关变化；</p>
								  </div>
								</template>
							</el-popover>
							<el-popover placement="bottom-start" title="道具申请方式" :width="500" trigger="hover" >
							    <template #reference>
							      <span>道具申请方式</span>
							    </template>
								<template #default>
								  <div>
									  <p>有道具返还的游戏，在申请返利的时候，将要申请的道具内容一并提交给客服（部分自选道具需要注明详细道具名称）；</p>
									  <p>如果已申请返利，但未备注道具，可联系客服协助；</p>
								  </div>
								</template>
							</el-popover>
							<el-popover placement="bottom" title="返利未到怎么办" :width="500" trigger="hover" >
							    <template #reference>
							      <span>返利未到怎么办</span>
							    </template>
								<template #default>
								  <div>
									  <p>① 请确认提交的申请信息完全正确，例如区服、角色、角色ID等。可在申请后关注申请是否被驳回，以及驳回原因，修正后重新提交即可；</p>
									  <p>② 请勿在返利未发放前修改游戏内角色名称，角色名称不对可能导致返利发放失败，甚至发放到其他角色；</p>
									  <p>③ 确实未到账，请联系客服协助咨询；</p>
								  </div>
								</template>
							</el-popover>
							<el-popover placement="bottom-end" title="返利到账不符" :width="500" trigger="hover" >
							    <template #reference>
							      <span>返利到账不符</span>
							    </template>
								<template #default>
								  <div>
									  <p>一般原因如下：</p>
									  <p>① 检查活动日期与充值时段是否匹配，如果不是活动期间的充值，那么是无法获得对应奖励的；</p>
									  <p>② 检查返利申请时是否忘记向客服填写道具奖励，以及道具名称是否详细，或特殊返利活动比例；</p>
									  <p>③ 其他原因导致的发放错误；</p>
									  <p>④ 根据活动返利比例和下方返利计算方法，核实是否计算有误；</p>
									  <p>以上问题，如发现返利内容和应得内容不符，可及时联系客服反馈，协助补齐奖励或了解原因。</p>
									  <el-link type="primary" :underline="false">返利计算方式：【充值金额】x【返利比例】x【游戏充值比例】=最终获取元宝数量</el-link>
									  <el-link type="info" :underline="false">举例：A游戏充值比例为1：300；您充值100元后，活动返利为10%，那么应得返利元宝：100x10%x300=3000元宝</el-link>
								  </div>
								</template>
							</el-popover>
							<el-popover placement="bottom-end" title="如何查看角色ID" :width="280" trigger="hover" >
							    <template #reference>
							      <span>如何查看角色ID</span>
							    </template>
								<template #default>
								  <div>
									  <p>不同游戏角色ID查看方法不同，但基本类型如下：</p>
									  <p>① 点开游戏内角色头像，可查看角色ID；</p>
									  <p>② 点击游戏内设置选项，可查看角色ID；</p>
									  <p>③ 如果没有id，可不提供</p>
								  </div>
								</template>
							</el-popover>
						</p>
					</div>
				</li>
				<li>
					<div class="iconBox green">
						<span>
							<i class="el-icon-s-finance"></i>
						</span>
					</div>
					<div class="desBox">
						<p class="title">充值帮助</p>
						<p>
							<el-popover placement="bottom-start" title="充值未到帐" :width="400" trigger="hover" >
							    <template #reference>
							      <span>充值未到帐</span>
							    </template>
								<template #default>
								  <div>
									  <p>① 【账号登录错误】请确认登录账号和充值账号是否一致；</p>
									  <p>② 【未支付成功】请确认是否正常通过支付宝或微信付款成功；</p>
									  <p>③ 【其他故障】支付成功，但游戏未到账，可退出游戏后重新登录查看，如果依然存在问题，请联系客服协助。（另外每笔订单支付间隔不要过短，低于一分钟的多笔支付可能存在充值异常导致无法到账）</p>
								  </div>
								</template>
							</el-popover>
							<el-popover placement="bottom-start" title="支付限额怎么办" :width="400" trigger="hover" >
							    <template #reference>
							      <span>支付限额怎么办</span>
							    </template>
								<template #default>
								  <div>
									  <p>当支付提示达到商户限额，可尝试切换稳定网络或4G提交，并适当降低单笔充值额度，分多次支付一般即可避免。如果游戏内或者活动有单笔充值金额要求，可选择分多次支付购买平台币，然后游戏内充值选择用平台币一次性付款。</p>
								  </div>
								</template>
							</el-popover>
						</p>
					</div>
					
				</li>
				<li>
					<div class="iconBox red">
						<span>
							<i class="el-icon-user-solid"></i>
						</span>
					</div>
					<div class="desBox">
						<p class="title">账户安全</p>
						<p>
							<el-popover placement="bottom-start" title="忘记账号" :width="500" trigger="hover" >
							    <template #reference>
							      <span>忘记账号</span>
							    </template>
								<template #default>
								  <div>
									  <p>账号充值过：联系客服提供任意一笔订单支付记录或支付记录截图，即可帮您查到对应账号；</p>
									  <p>账号未充值：联系客服提供IMEI码{在注册账号的手机中输入*#06#即可看到本机的IMEI码，刷机过或非注册账号手机IMEI码无法查询}；</p>
								  </div>
								</template>
							</el-popover>
							<el-popover placement="bottom-start" title="忘记密码" :width="600" trigger="hover" >
							    <template #reference>
							      <span>忘记密码</span>
							    </template>
								<template #default>
								  <div>
									  <p>【有绑定手机-号码可用】，可通过忘记密码选项自助进行找回修改密码；</p>
									  <p>【有绑定手机-号码无用】，可向客服提供账号和支付宝、微信支付方式的历史支付记录截图，核实；如未涉及账号交易或账号共享，且充值记录核实无误则可以找回；</p>
									  <p>【未绑定手机-有充值过】，可向客服提供账号和支付宝、微信支付方式的历史支付记录截图，核实找回。</p>
									  <p>【未绑定手机-没有充值】，可向客服提供IMEI码{在注册账号的手机中输入*#06#即可看到本机的IMEI码，刷机过或非注册账号手机IMEI码无法查询}协助核实找回。</p>
								  </div>
								</template>
							</el-popover>
							<el-popover placement="bottom-start" title="如何换绑手机号" :width="500" trigger="hover" >
							    <template #reference>
							      <span>如何换绑手机号</span>
							    </template>
								<template #default>
								  <div>
									  <p>① 在潇湘畅游平台内（我的--头像--手机绑定），通过当前绑定手机短信验证后即可解绑换绑；</p>
									  <p>② 如果之前绑定手机不可用，可向客服提供账号和支付宝、微信支付方式的历史支付记录截图，进行核实，未涉及账号交易或账号共享，且充值记录核实无误才可以协助换绑；</p>
								  </div>
								</template>
							</el-popover>
						</p>
					</div>
					
				</li>
				<li>
					<div class="iconBox blue">
						<span>
							<i class="el-icon-warning"></i>
						</span>
					</div>
					<div class="desBox">
						<p class="title">其他帮助</p>
						<p>
							<el-popover placement="top-start" title="下载登录异常" :width="520" trigger="hover" >
							    <template #reference>
							      <span>下载登录异常</span>
							    </template>
								<template #default>
								  <div>
									  <p>【下载很慢】</p>
									  <p>①请检查网络是否畅通，尝试切换网络（3G/4G/wifi）再下载；</p>
									  <p>②请检查手机存储空间是否充足；</p>
									  <p>如果依然无法下载，请联系客服咨询；</p>
									  <p>【登录异常】</p>
									  <p>①无法进入游戏--请检查当前游戏是否是维护中，如服务器正常可联系客服咨询；</p>
									  <p>②无限登录账号--联系客服核实是否为服务器故障；</p>
									  <p>③卡更新进度条--切换网络尝试或检查存储空间是否充足；</p>
									  <p>【长时间未登录进入保护状态】</p>
									  <p>保护状态是对用户账号安全的一种自动保护设置；遇到此类问题可直接联系客服协助恢复即可正常登录；</p>
								  </div>
								</template>
							</el-popover>
							<el-popover placement="top-start" title="游戏内悬浮球" :width="560" trigger="hover" >
							    <template #reference>
							      <span>游戏内悬浮球</span>
							    </template>
								<template #default>
								  <div>
									  <p>如何开启游戏内悬浮球：</p>
									  <p>方法1：手机【设置】--【应用管理】--当前游戏名称--【权限管理】--悬浮窗打勾允许</p>
									  <p>方法2：手机【手机管家】--【权限管理】--当前游戏名称--悬浮窗打勾允许</p>
								  </div>
								</template>
							</el-popover>
							<el-popover placement="top-start" title="电脑如何玩手游" :width="460" trigger="hover" >
							    <template #reference>
							      <span>电脑如何玩手游</span>
							    </template>
								<template #default>
								  <div>
									  <p>① 在电脑上安装安卓模拟器，可自行搜索选择下载，比如海马模拟器、夜神模拟器等；</p>
									  <p>② 在电脑上搜索下载奇葩游戏安装包/或直接搜索当前游戏安装包下载；</p>
									  <p>③ 打开模拟器，将此安装包拖进模拟器安装，即可正常使用。</p>
								  </div>
								</template>
							</el-popover>
							<el-popover placement="top-start" title="IOS设置信任" :width="460" trigger="hover" >
							    <template #reference>
							      <span>IOS设置信任</span>
							    </template>
								<template #default>
								  <div>
									  <p>由于ios9系统限制，游戏安装完成后，首次打开会提示“未受信任的企业级开发者”。</p>
									  <p>设置信任操作步骤如下：</p>
									  <p>【设置】--【通用】--【设备管理】--【文件描述】--【信任文件】</p>
								  </div>
								</template>
							</el-popover>
						</p>
					</div>
					
				</li>
			</ul>
		</div>
	</div>
	<Footer />
</template>

<script>
import Header from './HeaderEmpty.vue';
import Footer from './Footer.vue';
export default {
	components:{
		Header,Footer
	},
	data(){
		return{
			
		}
	},
}
</script>

<style lang="less" scoped>
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
  .service{
	  ul{
		  li{
			  display: flex;width: 100%;padding: 28px 32px;background: #fff;margin-bottom: 16px;box-sizing: border-box;
			  .iconBox{
				  width: 102px;height: 102px;border-radius: 100%;padding: 8px;box-sizing: border-box;
				  span{
					  display: flex;align-items: center;justify-content: center;
					  width: 100%;height: 100%;border-radius: 100%;
					  i{color: #fff;font-size: 46px;}
				  }
				  &.purple{
					  background: #B4AAF2;
					  span{
						  background: #897BE3;
					  }
				  }
				  &.blue{
					  background: #7DB9F7;
					  span{
						  background: #409EFF;
					  }
				  }
				  &.green{
					  background: #7CE8CE;
					  span{
						  background: #2ACDA5;
					  }
				  }
				  &.red{
					  background: #F8878C;
					  span{
						  background: #F8414A;
					  }
				  }
			  }
			  .desBox{
				  padding-left: 32px;
				  p.title{font-size: 20px;font-weight: bold;color: #4b4a49;height: 46px;line-height: 46px;margin-bottom: 12px;}
				  p{
					  span{
						  cursor: pointer;
						  font-size: 14px;color: #565656;padding-right: 12px;border-right: 5px solid rgba(0,0,0,0.1);margin-right: 12px;
						  &:last-child{border-right: none;}
					  }
				  }
			  }
		  }
	  }
  }
</style>
