<template>
<Header />
<div class="mainbody">
  <div class="main">
      <div class="title"><span>公司简介</span></div>
	  <div style="width: 100%;min-height: 300px;padding-top: 20px;">长沙潇湘畅游网络科技有限公司</div>
  </div>
  <div class="main">
      <div class="title"><span>联系我们</span></div>
      <div style="width: 100%;min-height: 300px;padding-top: 20px;">长沙潇湘畅游网络科技有限公司</div>
  </div>
</div>
<Footer />
</template>

<script>
// @ is an alias to /src
import Header from './HeaderEmpty.vue';
import Footer from './Footer.vue';
export default {
  name: 'Home',
  components: {
	  Header,
	  Footer
    },
	data(){
		return {
			hotGames:[],
			gameList:[],
			// 查询参数
			queryParams: {
			  orderByField: 'listorder',
			  current: 1,
			  ostype: 1
			},
		}
	},
	created() {
		
	},
    methods: {
  
    },
}
</script>
<style lang="less" scoped>
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
.mySwiper{
    width: 100%;height: 522px;
    .banner{
        width: 100%;min-width: 1200px;
        img{width: 100%;}
    }
}

</style>
