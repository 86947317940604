import { createRouter, createWebHistory } from 'vue-router'
import Index from '@/views/pc/Index.vue'
import mIndex from '@/views/m/Index.vue'
import Game from '@/views/pc/Game.vue'
import mGame from '@/views/m/Game.vue'
import Info from '@/views/pc/Info.vue'
import mInfo from '@/views/m/Info.vue'
import Service from '@/views/pc/Service.vue'
import mService from '@/views/m/Service.vue'
import Connect from '@/views/pc/Connect.vue'
import mConnect from '@/views/m/Connect.vue'
import About from '@/views/pc/About.vue'
import mAbout from '@/views/m/About.vue'
import ClausePrivacy from '@/views/ClausePrivacy.vue'
import ClauseUser from '@/views/ClauseUser.vue'
import IndexEmpty from '@/views/pc/IndexEmpty.vue'
import mIndexEmpty from '@/views/m/IndexEmpty.vue'

const routes = [
    {
		path: '/',
		name: 'Index',
		component: IndexEmpty
    },
	{
		path: '/pc',
		name: 'pcIndex',
		component: IndexEmpty
	},
	{
		path: '/m',
		name: 'mIndex',
		component: mIndexEmpty
	},
	// {
	// 	path: '/pc/game',
	// 	name: 'Game',
	// 	component: Game
	// },
	// {
	// 	path: '/m/game',
	// 	name: 'mGame',
	// 	component: mGame
	// },
	// {
	// 	path: '/pc/rank',
	// 	name: 'Rank',
	// 	component: Game
	// },
	// {
	// 	path: '/m/rank',
	// 	name: 'mRank',
	// 	component: mGame
	// },
	// {
	// 	path: '/pc/service',
	// 	name: 'Service',
	// 	component: Service
	// },
	// {
	// 	path: '/m/service',
	// 	name: 'mService',
	// 	component: mService
	// },
	{
		path: '/pc/connect',
		name: 'Connect',
		component: Connect
	},
	{
		path: '/m/connect',
		name: 'mConnect',
		component: mConnect
	},
	{
		path: '/pc/about',
		name: 'About',
		component: About
	},
	{
		path: '/m/about',
		name: 'mAbout',
		component: mAbout
	},
	// {
	// 	path: '/pc/game/:id',
	// 	name: 'Info',
	// 	component: Info
	// },
	// {
	// 	path: '/m/game/:id',
	// 	name: 'mInfo',
	// 	component: mInfo
	// },
	{
		path: '/clausePrivacy',
		name: 'ClausePrivacy',
		component: ClausePrivacy
	},
	{
		path: '/clauseUser',
		name: 'ClauseUser',
		component: ClauseUser
	},
	
]
  
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })
  
  export default router