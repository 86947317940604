<template>
	<div class="wrap">
		<div class="container">
			<div class="txt">
				<h2>隐私条款（PRIVACY CLAUSE）</h2>
				<p>本政策适用于长沙潇湘畅游网络科技有限公司及其关联方（以下合称 游戏 或 我们 ）负责开发、维护、运营的游戏相关产品或服务。 
				</p><p>
				 最近更新日期：2021年7月28日。 
				</p><p>
				 本政策将帮助您了解以下内容： 
				</p><a href="#part_1">
				  一、我们收集您的个人信息  
				</a><a href="#part_2">
				  二、我们如何使用您的个人信息  
				</a><a href="#part_3">
				  三、我们如何使用 Cookie  
				</a><a href="#part_4">
				  四、我们如何共享、转让、公开披露、存储您的个人信息  
				</a><a href="#part_5">
				  五、我们如何保护您的个人信息  
				</a><a href="#part_6">
				  六、您的权利  
				</a><a href="#part_7">
				  七、我们如何处理儿童的个人信息  
				</a><a href="#part_8">
				  八、本政策如何更新  
				</a><a href="#part_9">
				  九、如何联系我们  
				</a><p>
				 我们深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。 
				</p><p>
				 您知晓并确认，您在使用我们的产品或服务前，已仔细阅读并了解本《用户隐私协议》（下称 本隐私政策 或 本政策 ）的所有内容。提示您注意，我们对个人敏感信息都用（加粗加黑）的书写方式进行特别提醒。在向我们提供任何属于您和/或您的终端用户的个人敏感信息前，请您清楚考虑该等提供是恰当的并且同意我们可按本政策所述的目的和方式处理您和/或您的终端用户的个人敏感信息。我们会在得到您的同意后收集和使用您和/或您的终端用户的个人敏感信息，以实现 游戏为您提供的相关功能。您可以对您和/或您的终端用户个人敏感信息的收集与使用做出不同意的选择，但是拒绝提供这些信息，可能会影响 游戏为您提供相关服务功能。 
				</p>
				<h4 id="part_1">一、我们收集您的个人信息</h4>
				<p>
				 个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。 
				</p><p>
				 我们仅会出于本政策所述的以下目的，收集您的个人信息： 
				</p><p>
				  1、为满足相关法律法规政策及相关主管部门的要求，游戏用户需进行实名认证以继续使用游戏服务。我们会在您主动提供的情况下收集您的实名身份信息，包括身份证信息、军官证信息、护照信息，拒绝提供实名身份信息可能会导致您无法登陆游戏或在使用游戏服务过程中受到相应限制。  
				</p><p>
				  2、为了存储游戏用户产生的部分本地数据，我们会需要您提供设备存储的读写权限。如您拒绝提供设备存储的读写权限，我们将无法为您提供游戏服务。  
				</p><p>
				 3、为了标记您为游戏的用户以及用于账号发生归属纠纷的处理，如果您使用微信、QQ关联登录游戏，我们会收集您微信、QQ相应的唯一标识，（包括微信账号的昵称、头像、地区和性别，QQ账号的昵称、头像、性别），用于保存您的登录信息，以便您在不同设备登录以及方便您分享游戏内容。 
				</p><p>
				 4、为保障您正常使用游戏的游戏服务，确保游戏服务功能的正常运行，优化游戏产品体验，保障您的账号安全，在您注册或使用游戏游戏服务时，我们会收集您的个人常用设备信息、网络身份识别信息和网络环境信息，包括设备名称、网络类型、网络运营商、操作系统、应用版本号、设备型号、设备生产厂商包括IOS广告标识符（IDFA）、 国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、 国际移动用户识别码（IMSI）、MAC地址。 
				</p><p>
				 5、在您使用游戏服务的过程中，我们会收集您的游戏日志信息，包括登录日志、商店购买日志、活动日志、公会日志、升级日志、交友日志、聊天日志，以便您能够在客户端查看您的游戏历史记录，同时会用于游戏运营统计分析、客服投诉处理、游戏反作弊分析，以及游戏体验优化提升。 
				</p><p>
				 6、当您在游戏中进行充值、支付时，我们会收集您的充值记录、消费记录等信息，以便您查询您的交易记录，以及在交易出现争议时，为客服人员提供信息以支持争议诉求的处理。同时，为了尽最大程度保护您的财产安全，我们会收集支付时的设备信息、网络身份识别信息和网络环境信息，以对高风险交易行为进行识别、二次确认或阻断。 
				</p><p>
				 7、为保障您的游戏账号安全，营造公平、健康及安全的游戏环境，我们会收集您的游戏应用信息、设备信息、网络身份识别信息、操作系统及进程信息、网络环境信息及游戏崩溃记录等信息，以用于打击破坏游戏公平环境或干扰、破坏游戏服务正常进行的行为（如外挂作弊等）。 
				</p><p>
				 8、当您在游戏聊天室中或论坛上通过文字、图片、语音、视频及其他方式发布信息或与其他玩家进行互动时，我们可能会收集您发送的上述互动信息内容，用于对色情、暴力、政治、辱骂、恶意广告等不当内容进行过滤和监测，以净化游戏环境，维护健康的上网环境。 
				</p><p>
				 9、当您发生无法登录游戏、页面无法打开等错误时，为排除当地网络故障，为您提供更有针对性的游戏维护服务，我们会向您收集您的IP地址。 
				</p><p>
				 10、为方便您通过其他设备登录游戏，我们会提供二维码扫码登录功能。为此，我们将另行事先取得您的授权许可，以获取打开您设备照相机的权限。您拒绝提供打开设备照相机权限，不影响我们为您提供游戏服务。 
				</p><p>
				 11、为了提升您游戏内游客账户的安全性，防止游戏记录丢失，我们建议您将游戏内的游客账户与手机号码绑定，并将为此收集您的手机号码 。但您如拒绝提供，将不影响继续使用游戏服务。 
				</p><p>
				 12、为了向您提供您在游戏或活动中获得的实物奖励，可能会需要收集您的收件地址与 手机号码。如您拒绝提供，将不影响您继续使用游戏服务，但您将无法收到实物奖励。 
				</p><p>
				 13、根据相关法律法规及国家标准，以下情形中，我们可能会在未经您授权或同意的情况下，收集、使用您的相关个人信息： 
				 </p><p>
				（1）与国家安全、国防安全直接相关的；
				</p><p>
				（2）与公共安全、公共卫生、重大公共利益直接相关的；
				</p><p>
				（3）与刑事侦查、起诉、审判和判决执行等直接相关的；
				</p><p>
				（4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
				</p><p>
				（5）所收集的个人信息是您自行向社会公众公开的；
				</p><p>
				（6）根据您的要求签订和履行合同所必需的；
				</p><p>
				（7）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
				</p><p>
				（8）维护我们的服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
				</p><p>
				（9）开展合法的新闻报道所必需的；
				</p><p>
				（10）出于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的信息进行去标识化处理的；
				</p><p>
				（11）法律法规规定的其他情形。
				</p><p>
				 14、请您理解，我们向您提供的功能和服务是不断更新和发展的，如果某一功能或服务未在前述说明中且收集了您的信息，我们会通过页面提示、交互流程、网站公告、更新隐私政策等方式另行向您说明信息收集的内容、范围和目的，以征得您的同意。 
				</p>
				<h4 id="part_2">二、我们如何使用您的个人信息</h4>
				<p>
				 我们仅会出于本政策所述的如下目的与用途，使用您的个人信息： 
				</p><p>
				 1、为您提供各项游戏服务与功能，即基础游戏功能、客户服务。 
				</p><p>
				 2、产品问题修复及产品优化： 
				</p><p>
				 为了提升游戏用户服务体验，解决游戏在使用过程中可能出现的bug，我们会使用您在游戏服务使用过程中产生的日志信息，包括设备信息、地域信息（无精确定位）、游戏行为信息、交易信息。在对日志进行分析前，我们将对上述信息进行匿名化处理，而后整体对用户的类型及对应的游戏行为进行分析。 
				</p><p>
				 3、内外部审核： 
				</p><p>
				 为了履行法律法规及监管机构所规定的各项义务，在支持内外部的各类审核过程中，在签署保密协议的前提下，我们可能会向监管机构或审核机构提供匿名化处理后的个人信息、设备信息、交易信息。 
				</p><p>
				 当我们要将信息用于本政策未载明的其它用途时，会事先征求您的同意。 
				</p><p>
				 当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。 
				</p><p>
				 如属于对非必要的个人信息，您可以随时删除您已提供的该等个人信息，或者撤销已向我们提供的授权。删除信息、取消授权会导致您无法实现游戏对应的部分功能，但不会影响您使用游戏的基本功能。 
				</p>
				<h4 id="part_3">三、我们如何使用 Cookie</h4>
				<p>
				 Cookie 是由用户访问的网站向浏览器发送的一小段文本，它通常包含标识符、站点名称以及一些号码和字符。它帮助浏览器记录有关访问活动的信息，例如会话信息、首选语言和其他一些设置。这样，用户在下次访问同样的网站时就能获得更轻松实用的访问体验。 
				</p><p>
				我们使用Cookie以实现诸多目的，如记录用户会话信息、登录和操作状态、默认语言配置等。使用Cookie将使您在使用 游戏的服务时获得更好的体验。 
				</p><p>
				您同意我们收集并使用Cookie，我们不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。有关详情，请参见www.aboutcookies.org大部分网络浏览器都设有管理Cookie 的功能，如需详细了解如何更改浏览器设置，请访问相应浏览器的官方网站，如Internet Explorer、Google Chrome、Mozilla Firefox、Safari和Opera。 
				</p>
				<h4 id="part_4">四、我们如何共享、转让、公开披露、存储您的个人信息</h4>
				<p>
				 （一）共享 
				</p><p>
				 提请您注意，在您注册或使用游戏服务时，将由游戏与其关联公司整合所有资源为您提供服务，所以您的部分信息可能依据最小必要原则为提供相关服务在集团内部分享。我们承诺集团内所有公司将严格遵守本隐私政策的要求，受本隐私政策中所声明目的的约束。关联公司或附属公司若确有需要改变个人信息的处理目的，将再次征求您的授权同意。 
				</p><p>
				 我们不会与集团以外的任何公司、组织和个人分享您的个人信息，但以下情况除外： 
				</p><p>
				 1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。 
				</p><p>
				 2、我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。 
				</p><p>
				 3、与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息，确保符合最小化原则。我们的合作伙伴无权将共享的个人信息用于任何其他用途。目前，我们的授权合作伙伴包括以下类型： 
				</p><p>
				 1) 广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人敏感信息与提供广告、分析服务的合作伙伴分享。我们可能会向这些合作伙伴提供经过匿名化处理的信息，用于广告运营分析。匿名性会确保相关信息不会识别您个人。 
				</p><p>
				 2) 供应商和服务提供商。我们可能会将信息发送给支持我们业务的供应商、服务提供商，如支付服务商、物流服务商，以为您提供交易服务、邮递服务。 
				</p><p>
				 3) 联合运营合作伙伴。在游戏联合运营时，我们可能会与联合运营的合作伙伴共享您的信息，以支持客服反馈问题的跟踪处理、财务对账等客户支持或运营活动。 
				</p><p>
				 4、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。 
				</p><p>
				 （二）转让 
				</p><p>
				 我们不会将您的个人信息转让给集团以外的任何公司、组织和个人，但以下情况除外： 
				</p><p>
				 1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息； 
				</p><p>
				 2、在涉及合并、收购、业务板块出售、破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束。如变更个人信息收集的范围或使用目的时，我们将要求该公司、组织重新向您征求授权同意。 
				</p><p>
				 （三）公开披露 
				</p><p>
				 我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的个人信息：
				</p><p>
				1、根据您的需求，在您明确同意的披露方式下披露您所指定的信息；
				</p><p>
				2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。
				</p><p>
				（四）共享、转让、披露信息时事先征得授权同意的例外
				</p><p>
				以下情形中，共享、转让、披露您的信息无需事先征得您的授权同意：
				</p><p>
				1、与国家安全、国防安全直接相关的；
				</p><p>
				2、与公共安全、公共卫生、重大公共利益直接相关的；
				</p><p>
				3、与刑事侦查、起诉、审判和判决执行等司法或行政执法直接相关的；
				</p><p>
				4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
				</p><p>
				5、您自行向社会公众公开的信息；
				</p><p>
				6、从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道；
				</p><p>
				7、与我们履行法律法规规定的义务相关的。
				</p><p>
				根据法律规定，共享、转让、披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
				 </p><p>
				
				 （五）存储 
				</p><p>
				 1、个人信息存储地域：我们保证我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。未获得您的明示授权、法律法规的明确规定，及/或相关主管机构的要求，我们不会将您的个人信息转移出境。我们会通过安全的方式存储您的信息，包括本地存储（利用APP进行数据缓存）、数据库和服务器日志。 
				</p><p>
				 2、个人信息存储期限：我们保证只会在为实现服务目的所必需的时间内或法律法规规定的条件及期限下存储您的个人信息。在超出上述存储期限后，我们会对您的个人信息进行删除或匿名化处理。但您行使删除权、注销账号的或法律法规另有规定的除外（例如：《电子商务法》规定：商品和服务信息、交易信息保存时间自交易完成之日起不少于三年）。 
				</p>
				<h4 id="part_5">五、我们如何保护您的个人信息</h4>
				<p>
				 （一）我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，网络通信采用HTTPS协议进行加密保护；基于堡垒机的运维审计和访问控制，网络边界部署了防火墙、WAF（web应用防火墙）、抗DDOS流量清洗设备；敏感数据在数据库中通过非对称通信加密和严格的访问控制进行保护；在服务端的网络和终端层面部署了纵深安全防御体系，以免受恶意攻击；建立了完整的信息安全管理体系以规范日常的内部人员对数据的访问、使用、编辑、存储、共享、删除等活动，确保只有授权人员可以访问到受保护的信息；敏感数据访问、存储、使用的全生命周期监测预警；管理后台默认遮蔽和访问授权及审计控制；以及我们会举办信息安全和隐私保护培训课程及意识教育相关活动，加强员工对于保护个人信息重要性的认识。 
				</p><p>
				 （二）我们的数据安全能力：应用层加密，网络层加密，数据库加密，网络访问异常监测，数据库访问异常监测等。 
				</p><p>
				 （三）我们会采取一切合理可行的措施，确保不收集无关的个人信息。我们只会在达成本政策所述目的所需的最短期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。 
				</p><p>
				 （四）互联网并非绝对安全的环境，我们强烈建议您尽可能避免通过非加密的方式发送个人信息。请使用复杂密码（包含数字、大小写字母、特殊符号，并避免姓名、生日等信息），协助我们保证您的账号安全。 
				</p><p>
				 （五）我们将定期更新并公开安全风险、个人信息安全影响评估等报告的有关内容。您可通过访问我们的官方网站获得。 
				</p><p>
				 （六）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。 
				</p><p>
				 （七）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以信函、短信、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。 
				</p><p>
				（八）请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。
				</p><p>
				（九）请您务必妥善保管好您的帐号、密码及其他身份要素。您在使用我们的游戏时，我们会通过您的帐号、密码及其他身份要素来识别您的身份。一旦您泄露了前述信息，您可能会蒙受损失，并可能对您产生不利。如您发现帐号、密码及/或其他身份要素可能或已经泄露时，请您立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。
				</p>
				<h4 id="part_6">六、您的权利</h4>
				<p>
				 按照中国相关的法律、法规、标准，我们保障您对自己的个人信息行使以下权利： 
				</p><p>
				 （一）访问您的个人信息 
				</p><p>
				 1、您有权访问您的个人信息。如果您想行使数据访问权，可以在登录后，在个人中心，您可以查看账号信息，交易信息，虚拟财产信息，积分信息，站内信件等。 
				</p><p>
				 2、如果您无法通过上述方式访问这些个人信息，或希望访问您在使用我们的产品或服务过程中产生的其他个人信息，您可以通过游戏在线客服等，联系我们提出您的访问请求，我们将在15个工作日内回复您的访问请求。 
				</p><p>
				 （二）修改、更正您的个人信息 
				</p><p>
				 1、您有权对您的个人信息进行修改，法律法规规定的例外情况除外。如果您想行使数据修改、更正权，可以在登陆后，通过访问个人中心自行访问。在个人中心，您可以修改账号昵称、登陆密码、性别、QQ、学历、行业、收入、所在地、签名等信息。 
				</p><p>
				 2、当您发现我们处理的关于您的个人信息有错误而无法自行更正时，您有权要求我们做出更正。您可以通过服务热线（0731-82572771）、自助服务、在线客服等，联系我们提出您的更正请求，我们将在15个工作日内回复您的更正请求。 
				</p><p>
				 （三）删除您的个人信息 
				</p><p>
				 1、在以下情形中，您可以向我们提出删除个人信息的请求： 
				</p><p>
				 1) 如果我们处理个人信息的行为违反法律法规； 
				</p><p>
				 2) 如果我们收集、使用您的个人信息，却未征得您的同意； 
				</p><p>
				 3) 如果我们处理个人信息的行为违反了与您的约定； 
				</p><p>
				 4) 如果您不再使用我们的产品或服务，或您注销了账号； 
				</p><p>
				 5) 如果我们不再为您提供产品或服务。 
				</p><p>
				 2、若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。 
				</p><p>
				 3、当您从我们的服务中删除信息后，受限于系统运营我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。 
				</p><p>
				 （四）改变您授权同意的范围 
				</p><p>
				 1、 游戏在收集用户信息时遵循必要性原则，提供基本游戏服务功能需要一些基本的个人信息才能得以完成（见本政策第一条）。对于非必要部分的个人信息的收集和使用，您可以随时给予或收回您的授权同意。 
				</p><p>
				 2、您可以通过服务热线（0731-82572771）、自助服务、在线客服，联系我们提出您的请求。 
				</p><p>
				 3、当您撤销同意后，我们将不再处理相应的个人信息。但您撤销同意的决定，不会影响此前基于您的授权而开展的个人信息处理。 
				</p><p>
				 4、如果您不想接受我们给您发送的短信商业广告，您可以随时根据随附广告内容的退订指引进行操作。 
				</p><p>
				 （五）个人信息主体注销账户 
				</p><p>
				1、您可以通过进入游戏点击 设置 账号注销 自助提出用户注销申请，我们将在15个工作日内协助您注销账号。账号注销后，您将无法使用我们提供的服务。 
				</p><p>
				 2、如您是游戏的联运渠道用户，由于与您游戏相关的账户系由联运渠道方管理及运营，您可以按照联运渠道方提供的账号注销途径，申请注销账号。 
				</p><p>
				 3、如果您使用关联的微信、QQ账号作为游戏账号登录相关游戏，您需要注销微信、QQ账号，请在微信、QQ根据其指引进行注销。 
				</p><p>
				 4、您也可以通过服务热线（0731-82572771）、自助服务、在线客服等，联系我们提出您的账号注销请求。 
				</p><p>
				 5、在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。 
				</p><p>
				 （六）个人信息主体获取个人信息副本 
				</p><p>
				 1、您有权获取您的个人信息副本，您可以通过服务热线（0731-82572771）、自助服务、在线客服等，联系我们提出您的请求。 
				</p><p>
				 （七）约束信息系统自动决策 
				</p><p>
				 1、目前游戏尚未使用信息系统自动决策功能。未来，如果在某些业务功能中，我们使用仅依据信息系统、算法等在内的非人工自动决策机制做出决定的机制，我们将为您提供关闭自动决策的功能选项。 
				</p><p>
				 （八）响应您的上述请求 
				</p><p>
				 1、为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。 
				</p><p>
				 2、我们将在15个工作日内做出答复。若该等期限内因为客观原因的限制，确实无法给出答复的，我们将提前联系您延长答复期限，以给到您满意的答复。 
				</p><p>
				 3、如您不满意，还可以通过拨打客服电话进行投诉。 
				</p><p>
				 4、对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。 
				</p><p>
				 5、在以下情形中，我们将无法响应您的请求： 
				</p><p>
				 1) 与个人信息控制者履行法律法规规定的义务相关的； 
				</p><p>
				 2) 与国家安全、国防安全直接相关的； 
				</p><p>
				 3) 与公共安全、公共卫生、重大公共利益直接相关的； 
				</p><p>
				 4) 与犯罪侦查、起诉、审判和执行判决等直接相关的； 
				</p><p>
				 5) 个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的； 
				</p><p>
				 6) 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的； 
				</p><p>
				 7) 响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的； 
				</p><p>
				 8) 涉及商业秘密的。 
				</p>
				<h4 id="part_7">七、我们如何处理儿童的个人信息</h4>
				<p>
				 （一）我们高度重视儿童个人信息的保护问题，依照国家相关法律法规的规定，我们将不满 18 周岁的任何未成年人均视为儿童。游戏主要面向成人。如果没有父母或监护人的同意，儿童不应创建自己的个人账户，对于经父母同意而收集儿童个人信息的情况，我们只会在受到法律允许且提供游戏必要、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。  
				</p><p>
				 （二）我们会按照国家防沉迷政策要求，通过启用防沉迷系统保护未成年人（包括儿童）的合法权益。我们会通过实名身份等信息校验判断相关账号的实名信息是否为未成年人，进而决定是否将此账号纳入到防沉迷体系中。 
				</p><p>
				 （三）另外，我们还建立了家长监护平台。家长监护平台可以帮助家长或监护人随时了解孩子的游戏情况，设置消费限额以及每天的游戏时长和时段，协助儿童的家长或监护人保护儿童免受不良游戏习惯的伤害。 
				</p><p>
				 （四）我们收集、共享、转让、公开披露儿童的个人信息的范围同上述服务范围，但任何收集、存储、共享、转让、公开披露儿童的个人信息的行为均需事先经过儿童监护人的明示同意，若国家法律法规关于儿童个人信息保护出台新的政策限制针对儿童的个人信息的相关行为，我们将依照国家法律法规相应调整我们提供的收集、共享、转让、公开披露儿童的个人信息的范围。 
				</p><p>
				 （五）若您是儿童的法定监护人，请您关注您所监护的儿童是否是在取得您的授权同意之后使用 游戏的服务或提供其个人信息。如果儿童的监护人明确拒绝我们以上述方式向儿童提供服务，我们将依照法律规定及时删除我们收集、存储、使用的相关儿童个人信息，并停止向该儿童提供服务。 
				</p><p>
				 （六）我们收集到的儿童个人信息被定义为敏感信息，所有收集到的儿童个人信息均存储在中国大陆境内。为确保信息安全，我们采取加密等措施存储儿童个人信息。儿童个人信息会受到严格的技术保障措施的保护，例如，在收集儿童个人信息之后，我们会对其进行脱敏处理。此外， 游戏还建立了严格的数据安全管理流程，对于敏感数据的访问、使用进行了严格的限制，所有针对敏感信息的访问或权限申请都需要经过申请、审批并留存记录。 
				</p><p>
				 （七）我们有专人负责儿童个人信息保护，如果您对儿童个人信息保护有任何疑问或需求，您可以发邮件联系我司我们将在15个工作日内答复处理。 
				</p><p>
				 （八）就儿童个人信息而言，如其余条款与本协议第七条有冲突，以本协议第七条为准，本条未进行规定的，适用本协议其余条款。 
				</p>
				<h4 id="part_8">八、本政策如何更新</h4>
				<p>
				 1、我们的隐私政策可能变更。 
				</p><p>
				   2、当隐私政策的条款发生变更时，我们会在游戏版本更新时以页面提示、弹窗、网站公告等适当的方式向您提示变更后的内容。请您仔细阅读变更后的《隐私政策》。您继续使用  我们的游戏，表示您同意我们按照更新后的《隐私政策》收集、处理或使用您的个人信息。 
				</p>
				<h4 id="part_9">九、如何联系我们</h4>
				<p>
				 1、如果您对本隐私政策有任何疑问、意见或建议，您可以通过服务热线（0731-82572771）、自助服务、在线客服等，联系我们提出您的要求，我们会在15个工作日内进行答复。 
				</p>
			</div>
		</div>
	</div>
</template>

<script>
</script>

<style lang="less" scoped>
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	.wrap{
		position: relative;padding: 70px 0 0;margin: 0 auto;width: 100%;max-width: 768px;background: #fff;
		.container{
			width: 100%;padding: 0 20px 50px;min-height: calc(100vh - 70px);margin: 0 auto;box-sizing: border-box;
			.txt{
				p,a{
					font-size: 13px!important;
					line-height: 21px!important;
					color: #8c8c8c!important;
					margin: 16px 0 0 0!important;
				}
				a{ display: block;text-decoration: underline;color: #333!important; }
				h4{ margin: 16px 0 0 0!important; }
			}
		}
	}
</style>
