<template>
		<div class="bgwhite">
			<div class="topnav">
				<div class="logoImg">
					<router-link to="/">
						<img src="@/assets/images/logo-xiaox.png" />
						<!-- 潇湘畅游 -->
					</router-link>
				</div>
				<div class="searchBox">
					<el-input
						placeholder="输入游戏名称"
						v-model="searchInput">
						<template #append>
						<el-button icon="el-icon-search" @click="searchGame" />
					  </template>
					  </el-input>
				</div>
			</div>
		</div>
	<div id="nav">
	  <div class="header">
	    <div class="tabList">
	      <router-link to="/">首页</router-link>
	      <router-link to="/pc/game">游戏中心</router-link>
	      <router-link to="/pc/rank">排行榜</router-link>
	      <router-link to="/pc/service">客服中心</router-link>
	      <router-link to="/pc/connect">联系我们</router-link>
	    </div>
	  </div>
	</div>
</template>

<script>
	export default{
		data(){
		  return{
			  searchInput:''
		  }
		},
		methods: {
		  searchGame(){
			  if(this.$route.name == 'Game'){
					this.$emit('searchGame',this.searchInput);
			  }else{
				  this.$router.push({path:'/pc/game',query:{gameName:this.searchInput}})
			  }
		  }
		}
	}
</script>

<style lang="less" scoped>
  @import '@/assets/css/common';
</style>
