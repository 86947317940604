<template>
	<div class="con">
		<div class="icons">
			<div class="imgicon"><img :src="info.gameBigico" /></div>
			<div class="content">
				<h4>{{info.gameName}}</h4>
				<p>游戏类型：{{info.gameType}}</p>
				<p>上线时间：{{info.openTime}}</p>
				<p>文件大小：{{info.gameSize}}MB</p>
				<div class="btns">
					<a class="google" :href="info.downLink" target="_blank">安卓下载</a>
				</div>
			</div>
		</div>
		<div class="desc">
			<div class="title"><span>简介</span></div>
			<div class="description" v-html="info.gameDescription"></div>
			<div class="imglist">
				<el-image v-for="(item,index) in picList" :key="index" :src="item" :preview-src-list="picList"></el-image>
			</div>
		</div>
	</div>
</template>

<script>
	import { getGameDetail } from "@/api/index";
	export default {
		props:{
			id: {
				type: Number,
				required: true
			},
		},
		data(){
			return{
				info:{},
				picList:[],
			}
		},
		created(){
			this.getDetail();
		},
		methods:{
			getDetail(){
				getGameDetail(this.id).then(response => {
						this.info = response.data;
						let arr = [];
						arr.push(this.info.gamePic1);
						arr.push(this.info.gamePic2);
						arr.push(this.info.gamePic3);
						arr.push(this.info.gamePic4);
						arr.push(this.info.gamePic5);
						arr.map((item)=>{
							if(item!=null || item!=undefined){
								return item
							}
						})
						this.picList = arr;
					}
				);
			},
		}
	}
</script>

<style lang="less" scoped>
  @import '@/assets/css/common';
</style>
