<template>
	<Header />
	<div class="mainbody">
		<div class="conBox">
			<p>长沙潇湘畅游网络科技有限公司(简称潇湘畅游，下同)，公司主营国内的BT游戏运营，拥有自主研发的国内资深手机游戏服务发布平台，致力于服务用户，方便用户，打造优质的BT手游下载平台，让用户获得良好的体验。</p>
			<p>我们崇尚团队精英化，目前公司聚集了来自于国内名校及知名国内互联网上市公司的技术，设计，产品，运营，市场等方面的游戏精英50余人，并且团队规模还在不断扩大。</p>
			<p>我们崇尚的团队文化是：用户第一，正直诚信，敬业拼搏，合作共赢。</p>
			<p>《潇湘畅游》是一家创建不久的数字娱乐游戏平台,集团内的《奇葩游戏》平台拥有多年的行业运营经验。</p>
			<p>在市场具有强大的影响力，在同类市场份额中也达到行业领先位置。我们专注于移动互联网游戏产品的代理、发行及分发,致力于打造中国手机游戏平台,为玩家提供手机游戏公益服手游及手机网游无限元宝公益服游戏，致力于服务用户，方便用户，打造优质的BT手游下载平台，让用户获得良好的体验。</p>
		</div>
	</div>
	<Footer />
</template>

<script>
import Header from './HeaderEmpty.vue';
import Footer from './Footer.vue';
export default {
	components:{
		Header,Footer
	},
	data(){
		return{
			
		}
	},
}
</script>

<style lang="less" scoped>
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	.conBox{
		width: 100%;background: #fff;padding: 30px;min-height: calc(100vh - 330px);
		p{
			text-indent: 2em;font-size: 15px;line-height: 24px;margin-bottom: 16px;
		}
	}
</style>
