<template>
<Header @searchGame="searchGame" />
<div class="mainbody">
	<div v-show="active == 'Rank'" class="rankType">
		<a :class="{active:tag==`newgame`}" @click="newGameRank">新游榜</a>
		<a :class="{active:tag==`download`}" @click="downloadRank">下载榜</a>
		<a :class="{active:tag==`hotgame`}" @click="hotGameRank">热游榜</a>
	</div>
  <div class="gamelist" v-if="list.length > 0">
      <div class="item" v-for="(item,index) in list" :key="index">
		  <div class="iconbox" :class="`active`+((queryParams.current-1)*queryParams.size+index+1)">
			  <span class="tag">{{(queryParams.current-1)*queryParams.size+index+1}}</span>
			  <div class="icon">
				  <img :src="item.gameBigico" />
				  <a :href="'/pc/game/'+item.id" class="btn">立即下载</a>
			  </div>
		  </div>
		  <div class="descbox">
			  <h4>{{item.gameName}}</h4>
			  <div class="descs" v-html="item.gameDescription"></div>
			  <span>{{item.gameType}}</span>
		  </div>
		  <div class="imgsbox">
			  <ul>
				  <li><img :src="item.gamePic1"></li>
				  <li><img :src="item.gamePic2"></li>
				  <li><img :src="item.gamePic3"></li>
			  </ul>
		  </div>
	  </div>
  </div>
  <div class="gamelist empty" v-else>暂无数据</div>
	<div class="pageBox">
		<el-pagination background layout="prev, pager, next" 
			:total="total"
			@current-change="handleCurrentChange "></el-pagination>
	</div>
</div>
<Footer />
</template>

<script>
// @ is an alias to /src
import Header from './Header.vue';
import Footer from './Footer.vue';
import { getGameList } from "@/api/index";
export default {
	name: 'Game',
	components:{
		Header,Footer
	},
	data(){
		return{
			list:[],
			total:0,
			active:'',
			tag:'newgame',
			queryParams:{
				current:1,
				size:10
			}
		}
	},
	watch:{
		$route(to,fromurl){
			this.active = to.name;
			this.tag = 'newgame';
			this.queryParams = Object.assign({},{
				current:1,
				size:10
			})
			if(to.name != fromurl.name){
				if(to.query.gameName){
					this.queryParams.gameName = to.query.gameName;
				}
				this.getList();
			}
		}
	},
	created(){
		if(this.$route.query.gameName){
			this.queryParams.gameName = this.$route.query.gameName;
		}
		this.active = this.$route.name;
		this.getList();
	},
    methods: {
		searchGame(val){
			this.queryParams.gameName = val;
			this.getList();
		},
		downloadRank(){
			this.tag = 'download';
			this.queryParams = Object.assign({},{
				current:1,
				size:10,
				orderByField:'game_trueduration',
				isAsc:false,
				ostype:1
			})
			this.getList();
		},
		newGameRank(){
			this.tag = 'newgame';
			this.queryParams = Object.assign({},{
				current:1,
				size:10,
				orderByField:'listorder',
				isAsc:false,
				ostype:1
			})
			this.getList();
		},
		hotGameRank(){
			this.tag = 'hotgame';
			this.queryParams = Object.assign({},{
				current:1,
				size:10,
				isHot:1,
				orderByField:'listorder',
				isAsc:false,
				ostype:1
			})
			this.getList();
		},
		handleCurrentChange(val){
			this.queryParams.current = val;
			this.getList();
		},
		getList(){
			getGameList(this.queryParams).then(response => {
			    this.list = response.data.records;
				this.total = response.data.total;
			  }
			);
		},
    },
}
</script>
<style lang="less" scoped>
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	.pageBox{margin: 0 auto 20px;
	.el-pagination{text-align: center;}}
	.descs{display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 6;
		overflow: hidden;
		line-height: 22px!important;font-size: 13px!important;}
.gamelist{
	&.empty{text-align: center;}
	width: 100%;min-height: calc(100vh - 322px);
	.item{
		width: 100%;display: flex;height: 260px;overflow: hidden;margin-bottom: 20px;background: #fff;box-shadow: 0 0 20px #eee;
		.iconbox{
			width: 220px;position: relative;
			.tag{
				position: absolute;left: 0;top: 0;display: block;color: #fff;z-index: 111;padding: 5px 0 0 8px;
				&::before{
					width: 0;height: 0;border-top: 50px solid #999;border-right: 50px solid transparent;content: '';display: block;left: 0;top: 0;position: absolute;z-index: -1;
				}
			}
			.icon{
				display: flex;align-items: center;width: 150px;margin: 0 auto;flex-wrap: wrap;justify-content: center;height: 100%;padding: 20px 0;box-sizing: border-box;
				img{width: 100%;border-radius: 25px;}
				.btn{display: block;padding: 8px 20px;background: #ff5a00;color: #fff;}
			}
		}
		.descbox{
			flex: 1;background: #fafafa;height: 100%;padding: 22px 18px;box-sizing: border-box;
			h4{font-size: 24px;line-height: 30px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;margin-bottom: 15px;}
			span{display: inline-block;padding: 2px 8px;margin-top: 20px;font-size: 12px;border: 1px solid #ff5a00;color: #ff5a00;}
			p{line-height: 22px;font-size: 14px;}
		}
		.imgsbox{
			width: 420px;
			ul{
				display: flex;align-items: center;height: 100%;justify-content: space-between;
				li{
					width: 32%;height: 100%;text-align: center;overflow: hidden;
					img{height: 100%;transform: translate(-50%,0);margin-left: 50%;}
				}
			}
		}
		.active1{
			.tag::before{
				border-top: 50px solid #fa541c;
			}
		}
		.active2{
			.tag::before{
				border-top: 50px solid #ff7a45;
			}
		}
		.active3{
			.tag::before{
				border-top: 50px solid #ff9c6e;
			}
		}
	}
}
.rankType{
	width: 100%;display: flex;align-items: center;justify-content: flex-start;
	a{
		display: block;cursor: pointer;padding: 8px 20px;background: #fff;color: #909399;font-size: 14px;border-bottom: 1px solid #909399;border-radius: 15px 15px 0 0;
		&.active{
			background: #909399;color: #fff;
		}
	}
}
</style>
