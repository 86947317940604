<template>
<Header />
<div class="mbody">
	<div v-show="active == 'mRank'" class="rankType">
		<a :class="{active:tag==`newgame`}" @click="newGameRank">新游榜</a>
		<a :class="{active:tag==`download`}" @click="downloadRank">下载榜</a>
		<a :class="{active:tag==`hotgame`}" @click="hotGameRank">热游榜</a>
	</div>
  <div class="gamelist">
      <div class="item" v-for="(item,index) in list" :key="index">
		  <div class="iconbox">
			  <span class="tag">{{(queryParams.current-1)*queryParams.size+index+1}}</span>
			  <div class="icon">
				  <img :src="item.gameBigico" />
			  </div>
		  </div>
		  <div class="descbox">
			  <h4>{{item.gameName}}</h4>
			  <span>{{item.gameType}}</span>
			  <p>{{item.gameContent}}</p>
		  </div>
		  <div class="btnBox">
			  <a :href="'/pc/game/'+item.id" class="btn">立即下载</a>
		  </div>
	  </div>
  </div>
  <div class="pageBox">
  	<el-pagination background layout="prev, pager, next" 
  		:total="total"
  		@current-change="handleCurrentChange "></el-pagination>
  </div>
</div>
<Footer />
</template>

<script>
// @ is an alias to /src
import Header from './HeaderEmpty.vue';
import Footer from './Footer.vue';
import { getGameList } from "@/api/index";
export default {
	name: 'Game',
	components:{
		Header,Footer
	},
	data(){
		return{
			list:[],
			total:0,
			active:'',
			tag:'newgame',
			queryParams:{
				current:1,
				size:10
			}
		}
	},
	watch:{
		$route(to,from){
			this.active = to.name;
			this.tag = 'newgame';
			this.queryParams = Object.assign({},{
				current:1,
				size:10
			})
			this.getList();
		}
	},
	created(){
		this.active = this.$route.name;
		this.getList();
	},
    methods: {
		downloadRank(){
			this.tag = 'download';
			this.queryParams = Object.assign({},{
				current:1,
				size:10,
				orderByField:'game_trueduration',
				isAsc:false,
				ostype:1
			})
			this.getList();
		},
		newGameRank(){
			this.tag = 'newgame';
			this.queryParams = Object.assign({},{
				current:1,
				size:10,
				orderByField:'listorder',
				isAsc:false,
				ostype:1
			})
			this.getList();
		},
		hotGameRank(){
			this.tag = 'hotgame';
			this.queryParams = Object.assign({},{
				current:1,
				size:10,
				isHot:1,
				orderByField:'listorder',
				isAsc:false,
				ostype:1
			})
			this.getList();
		},
		handleCurrentChange(val){
			this.queryParams.current = val;
			this.getList();
		},
		getList(){
			getGameList(this.queryParams).then(response => {
			    this.list = response.data.records;
				this.total = response.data.total;
			  }
			);
		},
    },
}
</script>
<style lang="less" scoped>
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	.pageBox{margin: 0 auto 20px;
	.el-pagination{text-align: center;}}
.gamelist{
	width: 100%;
	.item{
		width: 100%;display: flex;height: 260px;overflow: hidden;margin-bottom: 20px;background: #fff;box-shadow: 0 0 20px #eee;
		.iconbox{
			width: 220px;position: relative;
			.tag{
				position: absolute;left: 0;top: 0;display: block;color: #fff;z-index: 111;padding: 5px 0 0 8px;
				&::before{
					width: 0;height: 0;border-top: 50px solid #999;border-right: 50px solid transparent;content: '';display: block;left: 0;top: 0;position: absolute;z-index: -1;
				}
			}
			.icon{
				display: flex;align-items: center;width: 150px;margin: 0 auto;flex-wrap: wrap;justify-content: center;height: 100%;padding: 20px 0;box-sizing: border-box;
				img{width: 100%;border-radius: 25px;}
				.btn{display: block;padding: 8px 20px;background: #ff5a00;color: #fff;}
			}
		}
		.descbox{
			flex: 1;background: #fafafa;height: 100%;padding: 22px 18px;box-sizing: border-box;
			h4{font-size: 24px;line-height: 30px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;margin-bottom: 15px;}
			span{display: inline-block;padding: 2px 8px;margin-top: 20px;font-size: 12px;border: 1px solid #ff5a00;color: #ff5a00;}
			p{line-height: 22px;font-size: 14px;}
		}
		.imgsbox{
			width: 420px;
			ul{
				display: flex;align-items: center;height: 100%;justify-content: space-between;
				li{
					width: 32%;height: 100%;text-align: center;overflow: hidden;
					img{height: 100%;transform: translate(-50%,0);margin-left: 50%;}
				}
			}
		}
		&:first-child{
			.iconbox{
				.tag::before{
					border-top: 50px solid #fa541c;
				}
			}
		}
		&:nth-child(2){
			.iconbox{
				.tag::before{
					border-top: 50px solid #ff7a45;
				}
			}
		}
		&:nth-child(3){
			.iconbox{
				.tag::before{
					border-top: 50px solid #ff9c6e;
				}
			}
		}
	}
}
.rankType{
	width: 100%;display: flex;align-items: center;justify-content: center;margin-bottom: -10px;margin-top: 10px;
	a{
		display: block;cursor: pointer;padding: 5px 20px;background: #fff;color: #F6505C;font-size: 14px;
		border: 1px solid #F6505C;border-radius: 10px;box-sizing: border-box;margin: 0 8px;
		&.active{
			background: #F6505C;color: #fff;
		}
	}
}
</style>
