<template>
	<Header />
	<div class="mbody">
		<div class="conBox">
		    <div class="main">
		        <div class="mapBox" id="mapBox"></div>
		        <div class="connectBox mt20">
		            <ul>
		                <li>
		                    <p class="title">商务合作</p>
		                    <p>联系人：刘蔚</p>
		                    <p>邮箱：liuwei@7pa.com</p>
		                </li>
		                <li>
		                    <p class="title">人事招聘</p>
		                    <p>联系人：HR刘女士</p>
		                    <p>邮箱：liusi@7pa.com</p>
		                </li>
		            </ul>
		        </div>
		        <div class="addresBox">
		            <ul>
		                <li>
		                    <p class="name">长沙潇湘畅游网络科技有限公司</p>
		                      <div class="lineBox">
		                          <p>地址：湖南省长沙市开福区新湖南大厦事业楼12层</p>
		                          <p>电话：0731-82572771</p>
		                      </div>
		                </li>
		            </ul>
		        </div>
		    </div>
		</div>
	</div>
	<Footer />
</template>

<script>
import Header from './HeaderEmpty.vue';
import Footer from './Footer.vue';
export default {
	components:{
		Header,Footer
	},
	data(){
		return{
			
		}
	},
	methods: {
			initMap () {
					// 定义地图中心点坐标
					var center = new window.TMap.LatLng(28.207093, 112.985141)
					// 定义map变量，调用 TMap.Map() 构造函数创建地图
					var map = new window.TMap.Map(document.getElementById('mapBox'), {
						center: center, // 设置地图中心点坐标
						zoom: 17.2, // 设置地图缩放级别
						pitch: 43.5, // 设置俯仰角
						rotation: 45 // 设置地图旋转角度
					})
					//初始化marker
					var marker = new window.TMap.MultiMarker({ //eslint-disable-line no-unused-vars
						map: map,
						geometries: [{ //点标注数据数组
							"position": center,
						}]
					});
					//初始化label
					var label = new window.TMap.MultiLabel({ //eslint-disable-line no-unused-vars
						map: map,
						styles: {
							'label': new window.TMap.LabelStyle({
								'color': '#3777FF', //颜色属性
								'size': 20, //文字大小属性
								'offset': { x: 0, y: 0 }, //文字偏移属性单位为像素
								'angle': 0, //文字旋转属性
								'alignment': 'center', //文字水平对齐属性
								'verticalAlignment': 'middle' //文字垂直对齐属性
							})
						},
						geometries: [{
							'id': 'label', //点图形数据的标志信息
							'styleId': 'label', //样式id
							'position': center, //标注点位置
							'content': '长沙潇湘畅游网络科技有限公司', //标注文本
							'properties': { //标注点的属性数据
								'title': 'label'
							}
						}]
					});
	
			  
			}
	},
	mounted () {
	  this.initMap()
	}
}
</script>

<style lang="less" scoped>
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	.conBox{
	    .main{
	        width: 100%;margin: 0 auto;padding: 0 0 20px;box-sizing:border-box;background: transparent;
	        .mapBox{width: 100%;background: #fff;height: 420px;}
	        .connectBox{
	            width: 100%;
	            ul{
	                width: 100%;display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap;
	                li{
	                    width: 100%;background: #fff;box-sizing:border-box;padding: 20px;box-shadow: 0px 0px 20px #ddd;margin-bottom: 16px;
	                    p{
	                        font-size: 14px;color: #666;line-height:24px;
	                        &.title{
	                            font-size: 18px;color: #333;margin-bottom: 8px;
	                        }
	                    }
	                }
	            }
	        }
	        .addresBox{
	            width: 100%;background: #fff;box-sizing:border-box;padding: 0 20px;box-shadow: 0px 0px 20px #ddd;
	            ul{
	                li{
	                    padding: 20px 0;border-bottom:1px dashed #eee;
	                    p.name{font-size: 18px;color: #333;margin-bottom: 15px;}
	                    .lineBox{
	                        width: 100%;
	                        p{
	                            font-size: 14px;color: #666;line-height: 22px;
	                        }
	                    }
						&:last-child{border-bottom: none;}
	                }
	            }
	            
	        }
	    }
	}
</style>
