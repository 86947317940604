<template>
<Header />
<div class="mbody">
  <swiper 
    :spaceBetween="30" 
    :centeredSlides="true" 
    :autoplay='{ "delay": 2500, "disableOnInteraction": false }' 
    :pagination='{ "clickable": true }' 
    :navigation="false" 
    class="mySwiper"
    >
	<swiper-slide v-for="(item,index) in gameList" :key="index">
		<div class="banner">
			<a :href="'/m/game/'+item.id">
				<img :src="item.gameAdvImage">
			</a>
		</div>
	</swiper-slide>
  </swiper>
  <div class="main">
      <div class="title"><span>热门游戏</span></div>
	  <div class="list">
		  <ul>
			  <li v-for="(item,index) in hotGames" :key="index">
				  <a :href="'/pc/game/'+item.id">
					  <div class="imgbox h150">
						  <img :src="item.gameAdvImage">
					  </div>
					  <div class="desbox">
						  <h4>{{item.gameName}}<a class="btn">安卓</a></h4>
					  </div>
				  </a>
			  </li>
		  </ul>
	  </div>
  </div>
</div>
<Footer />
</template>

<script>
// @ is an alias to /src
import Header from './HeaderEmpty.vue';
import Footer from './Footer.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.less';
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import SwiperCore, { Autoplay,Pagination,Navigation } from 'swiper/core';
SwiperCore.use([Autoplay,Pagination,Navigation]);
import { getGameList } from "@/api/index";
export default {
  name: 'Home',
  components: {
      Swiper,
      SwiperSlide,
	  Header,
	  Footer
    },
    data(){
    	return {
    		hotGames:[],
    		gameList:[],
    		// 查询参数
    		queryParams: {
    		  orderByField: 'listorder',
    		  current: 1,
    		  ostype: 1
    		},
    	}
    },
    created() {
    	this.getHotList();
    	this.getList();
    },
    methods: {
      /** 查询列表 */
      getHotList() {
    	let query = Object.assign({isHot:1,size:5},this.queryParams);
        getGameList(query).then(response => {
            this.hotGames = response.data.records;
          }
        );
      },
	  getList() {
	  		let query = Object.assign({size:10},this.queryParams);
	    getGameList(query).then(response => {
	        this.gameList = response.data.records;
	      }
	    );
	  },
    },
}
</script>
<style lang="less" scoped>
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
.mySwiper{
    width: 100%;background: #fff;
    .banner{
        width: 100%;font-size: 0;
        img{width: 100%;}
    }
}

</style>
