<template>
	  <router-view />
</template>

<script>
export default {
  name: 'App',
  watch: {
        $route(toUrl,fromUrl){
  			this.toPage(toUrl.path);
  		}
    },
  methods:{
	  _isMobile() {
	  	let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
	  	return flag;
	  },
	  toPage(url){
	  	if (this._isMobile()) {
	  		if(url == '/'){
	  			this.$router.replace('/m');
	  		}else{
	  			if(url.indexOf('/m')<0){
	  				let newurl = url.replace('/pc','/m');
	  				this.$router.replace(newurl);
	  			}else{
	  				this.$router.replace(url);
	  			}
	  		}
	  		// console.log("手机端");
	  	} else {
	  		// console.log("pc端");
	  		if(url.indexOf('/pc')<0){
	  			let newurl = url.replace('/m','/pc');
	  			this.$router.replace(newurl);
	  		}else{
	  			this.$router.replace(url);
	  		}
	  	}
	  },
  }
}
</script>

<style>
	body{margin: 0;padding: 0;background: #f3f3f3;}
</style>



